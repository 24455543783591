import { createApp, watch } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "./router";
import "./assets/stylesheets/main.scss";
// import "C:/Development/accesswireless-website/SitefinityWebApp/ResourcePackages/AccessWireless/assets/vendor/font-awesome/css/font-awesome.min.css";
// import "C:/Development/accesswireless-website/SitefinityWebApp/ResourcePackages/AccessWireless/assets/vendor/bootstrap/dist/css/bootstrap.min.css";
// import "C:/Development/accesswireless-website/SitefinityWebApp/ResourcePackages/AccessWireless/assets/css/main.css";



let offerTablet = window.offerTablet;


if (offerTablet === "Y") {

    const app = createApp(App);

    const pinia = createPinia();




    // Rehydrate state if present...
    // let previousState: any = localStorage.getItem("awApplicationState");
    // if (previousState !== null) {
    //   previousState = JSON.parse(previousState);
    //   pinia.state.value = previousState;
    // }

    // Sync local storage...
    // watch(
    //   pinia.state,
    //   (state) => {
    //     localStorage.setItem("awApplicationState", JSON.stringify(state));
    //   },
    //   { deep: true }
    // );

    app.use(pinia);

    app.use(router);


    app.provide("appInstance", app);
    app.mount("#awTabletOfferApp");
}

import { createRouter, createWebHashHistory, createWebHistory } from "vue-router";
import { useProgressStore } from "@/stores/progress";
/**
 * Application step route order MATTERS. The routes
 * are used to derive the user's "progress" through
 * the application.
 */
const router = createRouter({
  history: createWebHashHistory('/Account/'),
  routes: [
    {
      //Modal wrapper component for application steps
      path: "/",
      name: "",
      component: () => import("../views/ApplicationView.vue"),
      children: [
        {
          path: "eligible",
          name: "Eligible",
          alias: "/",
          component: () =>
            import("../components/application/steps/Eligible.vue"),
        },
        {
          path: "accept-attestations",
          name: "Accept Attestations",
          component: () =>
            import("../components/application/steps/AcceptAttestations.vue"),
        },
        {
          path: "bolt-on",
          name: "Bolt On",
          component: () => import("../components/application/steps/BoltOn.vue")
        },
        {
          path: "estimate",
          name: "estimate",
          component: () => import("../components/application/steps/Estimate.vue")
        },
        {
          path: "order",
          name: "Order",
          component: () => import("../components/application/steps/Order.vue")
        }
      ]
    },
  ]
});

// Update progress percentage programatically...
// @TODO hide back button at 100%, clear order information
router.beforeEach((to) => {
  const parentRoute = router.getRoutes().find((r) => r.name === "Application");

  const totalChildRoutes = parentRoute?.children.length;
  const currentRouteIndex = parentRoute?.children.findIndex(
    (c) => c.name === to.name
  );

  if (undefined !== currentRouteIndex && currentRouteIndex > -1) {
    const progress = useProgressStore();
    progress.$patch({
      totalSteps: totalChildRoutes,
      currentStep: currentRouteIndex + 1,
    });
  }
});

export default router;

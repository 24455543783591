import { defineStore } from "pinia";

interface State {
    imei: string; //grab this on mount()
    imeiValid: boolean;
    offerBo: string;//grab this on mount()
    imeiResultCode: number;
    user: {
        mdn: string;
        firstName: string;
        lastName: string;
        address1: string;
        address2: string;
        city: string;
        state: string;
        zipcode: string;
    },
    purchaseResponse: {
        resultCode: number;
        problemDesc: string;
        logTag: string;
        invoiceNo: string;
        subTotalPrePromo: string;
        promoAmount: string;
        subTotal: string;
        taxTotal: string;
        grandTotal: string;
    },
    purchase: {
        activNo: string;
        estimateOnlyYn: string;
        tabletImei: string;
        krReferralId: string;
        changeToBoPlanYn: string;
        promoId: string;
        ccNumber: string;
        ccExpDate: string;
        ccCvn: string;
        ccFirstName: string;
        ccLastName: string;
        ccAddress: string;
        ccCity: string;
        ccState: string;
        ccZip: string;
    }

}

function initialState(): State {
    return {
        imei: "",
        imeiValid: false,
        imeiResultCode: -1,
        offerBo: "",
        user: {
            mdn: "",
            firstName: "",
            lastName: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            zipcode: "",
        },
        purchaseResponse: {
            resultCode: -1,
            problemDesc: "",
            logTag: "",
            invoiceNo: "",
            subTotalPrePromo: "",
            promoAmount: "",
            subTotal: "",
            taxTotal: "",
            grandTotal: ""
        },
        purchase: {
            activNo: "",
            estimateOnlyYn: "",
            tabletImei: "",
            krReferralId: "",
            changeToBoPlanYn: "",
            promoId: "",
            ccNumber: "",
            ccExpDate: "",
            ccCvn: "",
            ccFirstName: "",
            ccLastName: "",
            ccAddress: "",
            ccCity: "",
            ccState: "",
            ccZip: "",
        }
    };
}

export const useApplicationStore = defineStore("application", {
    state: initialState
});
